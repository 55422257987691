<template>
  <div class="practice-setting">
    <!-- 标题 & 说明 -->
    <div class="title">
      <h2>专项练习</h2>
      <p>根据您选择的岗位和等级随机获取题目，题目将以选择题方式呈现，当题目答错时将对题目进行解析并给出正确答案。从而达到帮助您复习、巩固、强化知识点的目的。您可以邀请好友一起答题，系统将实时同步好友答题信息，答题完成后，将会对所有参赛者进行结果分析并给出成绩与排名。</p>
    </div>
    <div class="main">
      <!-- 练习设置 -->
      <div class="setting-form">
        <h6><i></i><span>练习设置</span></h6>
        <el-form ref="form" label-width="100px" :model="form" :rules="rules">
          <el-form-item label="岗位" prop="positionId" required>
            <PositionSelect :with-support-practice="true" v-model="form.positionId"/>
          </el-form-item>
          <el-form-item label="岗位等级" required>
            <el-radio-group class="primary-radio-group" v-model="form.positionLevel">
              <el-radio-button :label="0">所有</el-radio-button>
              <el-radio-button :label="1">初级</el-radio-button>
              <el-radio-button :label="2">中级</el-radio-button>
              <el-radio-button :label="3">高级</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="题目数量" required>
            <el-radio-group class="primary-radio-group" v-model="form.questionCount">
              <el-radio-button :label="10">10</el-radio-button>
              <el-radio-button :label="20">20</el-radio-button>
              <el-radio-button :label="50">50</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="opera">
          <Button type="solid" theme="function" size="big" :disabled="isWorking" @click="handleStart">开始答题</Button>
          <Button type="outline" theme="function" size="big">开始并邀请好友</Button>
          <p><img src="../../../assets/images/pk.png">邀请好友一起PK刷题，让学习更有乐趣！</p>
        </div>
      </div>
      <!-- 数据 -->
      <div class="data-wrap">
        <!-- 最近练习 -->
        <div class="data-block">
          <div class="data-list">
            <Barrage/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startPractice } from '@/api/user.practice'
import Barrage from '@/components/common/Barrage'
import PositionSelect from '@/components/common/PositionSelect'
export default {
  components: { PositionSelect, Barrage },
  data () {
    return {
      isWorking: false,
      form: {
        positionId: null,
        positionLevel: 0,
        questionCount: 10
      },
      rules: {
        positionId: {
          required: true, message: '请选择岗位'
        }
      }
    }
  },
  methods: {
    // 开始答题
    handleStart () {
      if (this.isWorking) {
        return
      }
      this.$refs.form.validate(pass => {
        if (!pass) {
          return
        }
        this.isWorking = true
        startPractice(this.form)
          .then(data => {
            this.$router.push({ name: 'Practice', params: { practiceNo: data } })
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.practice-setting {
  margin: 20px 0;
  padding: 40px 70px;
  box-sizing: border-box;
  background: #ffffff;
  /* 标题 & 说明 */
  .title {
    margin-bottom: 30px;
    h2 {
      text-align: center;
      margin: 0 0 30px 0;
      font-size: 30px;
      font-weight: normal;
    }
    p {
      margin: 0;
      color: $font-color-third;
      font-size: 16px;
      line-height: 26px;
      font-weight: lighter;
      letter-spacing: 1px;
      text-indent: 34px;
    }
  }
  // 设置区域
  .main {
    padding: 20px 0 20px 40px;
    display: flex;
    // background-color: $background-color-second + 5;
    // 设置表单
    .setting-form {
      width: 500px;
      flex-shrink: 0;
      box-shadow: $background-shadow;
      border-radius: 20px;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      h6 {
        margin: 0 0 30px 0;
        font-size: 16px;
        font-weight: normal;
        position: relative;
        padding-left: 15px;
        i {
          display: inline-block;
          width: 5px;
          height: 19px;
          background: $primary-color;
          position: absolute;
          top: 2px;
          left: 0;
          border-radius: 3px;
        }
      }
      .el-form {
        .el-form-item {
          margin-bottom: 30px;
          /deep/ .el-form-item__error {
            margin-top: 5px;
          }
        }
      }
      .opera {
        margin-top: 40px;
        padding-left: 100px;
        color: $font-color-third;
        p {
          font-size: 13px;
          display: flex;
          align-items: center;
          img {
            width: 40px;
            margin-right: 10px;
          }
        }
      }
    }
    // 练习数据
    .data-wrap {
      flex-grow: 1;
      flex-shrink: 0;
      .data-block {
        border-radius: 20px;
        h6 {
          margin: 0 0 10px 0;
          font-weight: normal;
          text-align: center;
          padding: 10px 0;
          color: $font-color-third;
          font-size: 12px;
        }
        .data-list {
          height: 300px;
          position: relative;
          margin-top: 30px;
          ul {
            padding: 0;
            margin: 0 0 15px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $background-color-second;
            opacity: .5;
            li {
              margin-right: 20px;
              &:last-of-type {
                margin-right: 0;
                width: 200px;
                flex-shrink: 0;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
</style>
