<template>
  <div class="barrage">
    <template v-if="!loading">
      <div v-for="group in groups" :key="group.id" class="line" :style="lineStyle">
        <div class="barrage-item" v-for="item in group.list" :key="item.id" :id="item.id" :style="item.style">
          <img :src="item.img">
          <p>{{item.content}}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Barrage',
  props: {
    // 动画基础时间，表示从0-2000px的执行时间
    duration: {
      default: 20
    },
    // 行数
    lineCount: {
      default: 5
    },
    // 行高
    lineHeight: {
      default: '40px'
    },
    // 弹幕间距
    gap: {
      default: 300
    }
  },
  data () {
    return {
      loading: true,
      interval: null,
      elWidth: 0,
      groups: [],
      data: [
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        },
        {
          img: '/avatar.png',
          content: 'WEB前端开发工程师/3分钟前'
        }
      ]
    }
  },
  computed: {
    lineStyle () {
      return {
        height: this.lineHeight
      }
    }
  },
  methods: {
    // 修饰弹幕
    packageItem (item, index) {
      if (item.style == null) {
        item.style = {}
      }
      const translateX = (index + 1) * this.gap
      // 每秒速度 = 总距离 / 耗时
      const unitPx = Math.round(2000 / this.duration)
      // 计算额外耗时（实现后者速率匀速加快）
      const additionDuration = Math.ceil(translateX / unitPx)
      // 计算耗时偏移量（实现变速）
      const offsetDuration = Math.random() * this.elWidth / unitPx
      item.style.transform = `translateX(${translateX}px)`
      item.style['animation-duration'] = `${this.duration + additionDuration + offsetDuration}s`
    },
    // 分组
    buildGroup () {
      const capacity = Math.ceil(this.data.length / this.lineCount)
      const groups = []
      for (let i = 0; i < this.lineCount; i++) {
        const groupId = '' + Math.round(Math.random() * 1000)
        groups.push({
          id: groupId,
          list: this.data.slice(capacity * i, capacity * i + capacity).map((item, index) => {
            const newItem = {
              id: '' + groupId + Math.round(Math.random() * 1000000),
              ...item
            }
            this.packageItem(newItem, index)
            return newItem
          })
        })
        if (capacity * i + capacity > this.data.length) {
          break
        }
      }
      this.groups = groups
    },
    // 重载弹幕
    reloadItems (ids) {
      const targetItemInfos = []
      for (const group of this.groups) {
        for (let i = 0; i < group.list.length; i++) {
          if (ids.findIndex(id => id === group.list[i].id) !== -1) {
            targetItemInfos.push({
              group: group,
              itemId: group.list[i].id
            })
          }
        }
      }
      if (targetItemInfos.length > 0) {
        const targetItems = []
        for (const target of targetItemInfos) {
          const targetItemIndex = target.group.list.findIndex(item => item.id === target.itemId)
          const targetItem = target.group.list.splice(targetItemIndex, 1)[0]
          targetItems.push({
            group: target.group,
            item: targetItem
          })
        }
        this.$nextTick(() => {
          for (const target of targetItems) {
            this.packageItem(target.item, target.group.list.length - 1)
            target.group.list.push(target.item)
          }
        })
      }
    }
  },
  mounted () {
    this.elWidth = this.$el.clientWidth
    this.loading = false
    this.buildGroup()
    this.interval = setInterval(() => {
      const barrageItems = this.$el.querySelectorAll('.barrage-item')
      const reloadIds = []
      for (const item of barrageItems) {
        if (item.getBoundingClientRect().right < this.$el.getBoundingClientRect().left) {
          reloadIds.push(item.id)
        }
      }
      this.reloadItems(reloadIds)
    }, 300)
  },
  beforeDestroy () {
    if (this.interval != null) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped lang="scss">
.barrage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // 行
  .line {
    position: relative;
    margin-bottom: 20px;
  }
  .barrage-item {
    height: 40px;
    border-radius: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    background-color: rgba(240, 240, 240, .75);
    animation: move;
    position: absolute;
    user-select: none;
    img {
      width: 30px;
      flex-shrink: 0;
      border-radius: 50%;
      margin-right: 10px;
    }
    p {
      margin: 0;
      font-size: 12px;
      color: #333;
    }
  }
}
@keyframes move {
  100% {
    transform: translateX(-2000px);
  }
}
</style>
