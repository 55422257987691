<template>
  <el-select class="position-select" :value="value" @change="$emit('input', $event)">
    <el-option v-for="position in list" :value="position.id" :label="position.name" :key="position.id"/>
  </el-select>
</template>

<script>
import { fetchPositionList } from '@/api/basic'

export default {
  name: 'PositionSelect',
  props: {
    value: {},
    // 是否包含支持面试的岗位
    withSupportInterview: {
      default: false
    },
    // 是否包含支持练习的岗位
    withSupportPractice: {
      default: false
    }
  },
  data () {
    return {
      list: []
    }
  },
  created () {
    fetchPositionList({
      supportInterview: this.withSupportInterview,
      supportPractice: this.withSupportPractice
    })
      .then(data => {
        this.list = data
      })
      .catch(e => {
        this.$tip.apiFailed(e)
      })
  }
}
</script>
